export default {
  nav: {
    main: {
      assets: 'Master data',
      operations: 'Operations',
      kpn: 'KPN',
      telemetry: 'Telemetry',
    },
    telemetry: {
      lppRecord: 'LPP Records',
    },
    kpn: {
      kpnDevices: 'Devices',
      uplink: 'Uplinks',
      downlink: 'Downlinks',
    },
    operations: {
      devices: 'Devices',
      firmwareVersion: 'Firmware version',
      hardwareVersion: 'Hardware version',
      batch: 'RLC Batch',
      rainBarrel: 'Rain barrel',

    },
    assets: {
      users: 'Users',
      globalValues: 'Settings',
      globalFiles: 'Special files',
    },
    audit: {
      accessLog: 'Access Log',
    },
    account: {
      account: 'Account',
      changelog: 'Changelog'
    }
  },
  user: {
    overview: {
      title: 'Users',
      addButton: 'Add User',
    },
    create: {
      title: 'Create User',
    },
    edit: {
      title: 'Edit User',
    },
    field: {
      fullName: { label: 'Name' },
      firstName: { label: 'First Name' },
      lastName: { label: 'Last Name' },
      email: { label: 'Email' },
      password: { label: 'Password' },
      dateJoined: { label: 'Added At' },
      groups: {
        label: 'Groups',
        value: {
          superuser: 'Superuser',
          admin: 'Admin',
          manager: 'Manager',
          operator: 'Operator',
          external_api: 'API',
          production: 'Production',
        },
        empty: 'No groups assigned',
      },
      language: {
        label: 'Language'
      },
      isActive: {
        label: 'Users',
        options: {
          all: 'All',
          yes: 'Active',
          no: 'Not Active',
        }
      },
    },
    login: {
      title: 'Login',
      forgotPasswordLink: 'Forgot password?',
      errors: {
        invalidCredentials: 'Username or password do not match',
        unknown: 'Unknown error, status code: {{status}}',
      },
      loginButton: 'Log In',
    },
    passwordForgot: {
      title: 'Forgot password',
      requestButton: 'Send Email',
      requestedEmailText:
        'An email with a reset code has been sent to this email address.',
      loggedInError: 'You are logged in. Password forgot only works when you are not logged in.',
    },
    resetPassword: {
      title: 'Reset password',
      resetButton: 'Reset',
      saveSuccess: 'Password is successfully reset.',
      loggedInError: 'You are logged in. Password reset only works when you are not logged in.',
    },
    changePassword: {
      title: 'Change Password',
      field: {
        passwordOld: {
          label: 'Current password',
        },
        passwordNew: {
          label: 'New password',
        },
      },
      saveButton: 'Save',
    },
    account: {
      title: 'Account Details',
      logoutButton: 'Log Out',
      info: {
        title: 'Account Info',
      },
      password: {
        title: 'Change Password',
      },
      logout: {
        title: 'Log Out',
        confirm: 'Are you sure you want to logout?'
      },
    },
  },
  myFilter: {
    custom: 'Custom',
    action: {
      unsetDefault: 'Unset Default',
      setDefault: 'Set Default',
      setCurrent: 'Save Current Filters',
      delete: 'Delete',
    },
  },
  filters: {
    deletedFilter: {
      label: 'Deleted',
      withoutDeleted: 'Without deleted',
      onlyDeleted: 'Only deleted',
      bothDeletedAndNot: 'both'
    },
    notesFilter: {
      label: 'Notes',
      withoutNotes: 'Without notes',
      onlyNotes: 'Only notes',
      bothNotesAndNot: 'both'
    }
  },

  globalValue: {
    edit: {
      title: 'Edit value of global setting',
    },
    overview: {
      title: 'Global settings',
    },
    field: {
      key: {
        label: 'Setting name',
      },
      value: {
        label: 'Value',
      },
      writable: {
        label: 'Writable?',
      },
      readable: {
        label: 'Readable?',
      },
    },
  },
  globalFile: {
    edit: {
      title: 'Edit special file',
    },
    overview: {
      title: 'Special files',
    },
    field: {
      key: {
        label: 'Name for file',
      },
      value: {
        label: 'File',
      },
      writable: {
        label: 'Writable?',
      },
      readable: {
        label: 'Readable?',
      },
    },
  },
  accessLogEntry: {
    overview: {
      title: 'Audit log',
    },
    field: {
      originalUser: {
        label: 'Original user',
      },
      masqueradingAsUser: {
        label: 'Masquerading as user',
      },
      ipAddress: {
        label: 'IP address',
      },
      method: {
        label: 'HTTP method',
      },
      endpoint: {
        label: 'Endpoint',
      },
      statusCode: {
        label: 'Status code',
      },
      additionalInfo: {
        label: 'Extra information',
      },
      requestTime: {
        label: 'Time of access',
      },
    },
  },
  tooltips: {
    masquerade: 'Masquerade',
    edit: 'Edit',
    delete: 'Delete',
    view: 'View',
    restore: 'Restore',
  },
  form: {
    actions: 'Actions',
    search: 'Search',
    repeat: 'Repeat',
    fileType: {
      pdf: 'PDF',
      image: 'IMG',
      xls: 'XLS',
      any: 'Other',
      none: 'No file uploaded yet',
    },
    saveButton: 'Save',
    multiPick: {
      searchPlaceholder: 'Select…',
      selectedText: '$1 of $2 selected',
      noneSelectedText: 'None selected',
      selectAllButton: 'All',
      selectNoneButton: 'None',
    },
    notifications: {
      saveSuccess: 'Saved successfully',
      deleteSuccess: 'Deleted successfully',
      saveError: 'Error with saving ({{status}})',
      saveValError: 'Not all information is filled in correctly',
      saveAuthError: 'Invalid credentials',
      newAppVersion:
        'New version available, click to reload page as soon as possible',
    },
    submitButton: 'Submit',
    cancelButton: 'Cancel',
    addButton: 'Add',
    startDate: 'Start Date',
    endDate: 'End Date',
    deleteConfirmation: 'Are you sure you want to delete this entity?',
    restoreConfirmation: 'Are you sure you want to restore this entity?'
  },
  crash: {
    heading: 'Application crashed',
    subHeading1: 'We’re very sorry, but the application has crashed.',
    subHeading2: 'Our team has been notified.',
    reload: 'Reload page'
  },
  daycy: {
    week: {
      label: 'Wk',
      number: '{{weekNumber}}',
    },
    weekDay: {
      monday: 'Mo',
      tuesday: 'Tu',
      wednesday: 'We',
      thursday: 'Th',
      friday: 'Fr',
      saturday: 'Sa',
      sunday: 'Su',
    },
    month: {
      january: 'January',
      february: 'February',
      march: 'March',
      april: 'April',
      may: 'May',
      june: 'June',
      july: 'July',
      august: 'August',
      september: 'September',
      october: 'October',
      november: 'November',
      december: 'December',
    },
  },
  'batch': {
    'overview': {
      'title': 'RLC batches',
      'resultsTitle': 'RLC batches search results',
    },
    'nav': {
      'overview': 'RLC batches'
    },
    'field': {
      id: { label: 'ID' },
      label: { label: 'name' },
      createdAt: { label: 'Created at' },
      notes: { label: 'Notes' },
    },

  },
  'hardwareVersion': {
    'overview': {
      'title': 'Hardware versions',
      'resultsTitle': 'Hardware version search results',
    },
    'nav': {
      'overview': 'Hardware versions'
    },
    'field': {
      id: { label: 'ID' },
      version: { label: 'Version' },
      createdAt: { label: 'Created at' },
      notes: { label: 'Notes' },
    },
    'create': {
      'title': 'Add hardware version'
    },
    'delete': {
      'error': 'Could not delete hardware version',
      'success': 'Hardware version removed'
    },
    'edit': {
      'pagesTitle': 'Pages',
      'title': 'Edit hardware version'
    },
    'createButton': 'Add hardware version',
    'editTooltip': 'Edit hardware version',
  },
  'rainBarrel': {
    'overview': {
      'title': 'Rain barrels',
      'resultsTitle': 'Rain barrels search results',
    },
    'nav': {
      'overview': 'Rain barrels'
    },
    'field': {
      id: { label: 'ID' },
      name: { label: 'Name' },
      width: { label: 'Width (mm)' },
      height: { label: 'Height (mm)' },
      depth: { label: 'Depth (mm)' },
      volume: { label: 'Volume (m³)' },
      createdAt: { label: 'Created at' },
      notes: { label: 'Notes' },
    },
    'create': {
      'title': 'Add rain barrel'
    },
    'delete': {
      'error': 'Could not delete rain barrel',
      'success': 'Rain barrel removed'
    },
    'edit': {
      'pagesTitle': 'Pages',
      'title': 'Edit rain barrel'
    },
    'createButton': 'Add rain barrel',
    'editTooltip': 'Edit rain barrel',
  },
  'firmwareVersion': {
    'overview': {
      'title': 'Firmware versions',
      'resultsTitle': 'Search results',
    },
    'nav': {
      'overview': 'Firmware versions'
    },
    'field': {
      id: { label: 'ID' },
      version: { label: 'Version' },
      link: { label: 'Link' },
      createdAt: { label: 'Created at' },
      notes: { label: 'Notes' },
    },
    'create': {
      'title': 'Add firmware version'
    },
    'delete': {
      'error': 'Could not delete firmware version',
      'success': 'Firmware version removed'
    },
    'edit': {
      'pagesTitle': 'Pages',
      'title': 'Edit firmware version'
    },
    'createButton': 'Add firmware version',
    'editTooltip': 'Edit firmware version',
  },
  'location': {
    'overview': {
      'title': 'Locations',
      'resultsTitle': 'Search results',
    },
    'nav': {
      'overview': 'Locations'
    },
    'field': {
      id: { label: 'ID' },
      identifier: { label: 'Identifier' },
      createdAt: { label: 'Created at' },
      notes: { label: 'Notes' },
    },
    'create': {
      'title': 'Add location'
    },
    'delete': {
      'error': 'Could not delete location',
      'success': 'location removed'
    },
    'edit': {
      'pagesTitle': 'Pages',
      'title': 'Edit location'
    },
    'createButton': 'Add location',
    'editTooltip': 'Edit location',
  },
  'device': {
    'overview': {
      'title': 'Devices',
      'resultsTitle': 'Search results',
    },
    'nav': {
      'overview': 'Devices'
    },
    'field': {
      id: { label: 'ID' },
      cohort: { label: 'Cohort' },
      devui: { label: 'Device UI' },
      appkey: { label: 'App key' },
      firmwareVersion: { label: 'Firmware' },
      hardwareVersion: { label: 'Hardware' },
      batch: { label: 'RLC Batch' },
      operationalStatus: { label: 'Operational status' },
      group: { label: 'Group' },
      rainBarrel: { label: 'Rain barrel' },
      location: { label: 'Location' },
      identifier: { label: 'Identifier' },
      createdAt: { label: 'Created at' },
      notes: { label: 'Notes' },
      shipped: { label: 'Shipped' },
      lastMessageAt: { label: 'Message status' },
    },
    'create': {
      'title': 'Add device'
    },
    'delete': {
      'error': 'Could not delete device',
      'success': 'Device deleted'
    },
    'edit': {
      'pagesTitle': 'Pages',
      'title': 'Edit page'
    },
    'createButton': 'Add device',
    'editTooltip': 'Edit device',
  },
  uplink: {
    overview: {
      title: 'Uplinks'
    },
    field: {
      id: { label: 'ID' },
      payload: { label: 'Payload' },
      receivedAt: { label: 'Received at' },
      processedAt: { label: 'Processed at' },
    }
  },
  downlink: {
    overview: {
      title: 'Downlinks',
      resultsTitle: 'Search results'
    },
    field: {
      id: { label: 'ID' },
      device: { label: 'Device ID' },
      payload: { label: 'Payload' },
      status: { label: 'Status' },
      failureReason: { label: 'Failure reason' },
      sentAt: { label: 'Sent at' },
      createdAt: { label: 'Created at' },

      pumpActivated: { label: 'Activate pump' },
      pumpDuration: { label: 'Duration in seconds (1 - 3600)' },
      pumpChoice: { label: 'Pump choice' },

      selectionValveActivated: { label: 'Activate selection valve' },
      selectionValveChoice: { label: 'Selection valve choice' },


      dumpValveDuration: { label: 'Open dump valve for x seconds' },
      dumpValveChoice: { label: 'Dump valve choice' },
      dumpValveActivated: { label: 'Activate dump valve' },
    },
    control: {
      pump: 'Control pump',
      dump_valve: 'Control dump valve'
    }
  },
  pageNotFoundMessage: 'Page not found',
  brandingMessage: 'Powered by Theta Vision',
  lppRecord: {
    overview: {
      title: 'LPP Records',
      resultsTitle: 'Search results'
    },
    field: {
      id: { label: 'ID' },
      createdAt: { label: 'Created at' },
      channel: { label: 'Channel' },
      value: { label: 'Value' },
      dataType: { label: 'Data type' },
      device: { label: 'Device id' },
      uplink: { label: 'Uplink id' },
    }
  },
};
