// Hacky helper to ease working with notifications.
let viewStore = null;

export function configureCurrentUser(givenViewStore) {
  viewStore = givenViewStore;
}

export function getCurrentUser() {
  return viewStore.currentUser;
}

export function userInGroup(groupNames) {
  return getCurrentUser().inGroup(groupNames);
}
