import PropTypes from 'prop-types';
import React, { Component } from 'react';
import View from 'spider/store/View';
import { Route as BaseRoute, Switch, Redirect } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import Load from './Loadable';
import { ModuleRepository } from '_iae/module/repository';;
// Use the sentry route to enable parameter discovery by react router before
// sending that information to sentry
const Route = Sentry.withSentryRouting(BaseRoute);

const UserOverview = Load(() => import('../screen/User/Overview'));
const UserEdit = Load(() => import('../screen/User/Edit'));
const GlobalValueOverview = Load(() => import('../screen/GlobalValue/Overview'));
const GlobalValueEdit = Load(() => import('../screen/GlobalValue/Edit'));
const GlobalFileOverview = Load(() => import('../screen/GlobalFile/Overview'));
const GlobalFileEdit = Load(() => import('../screen/GlobalFile/Edit'));
const AccessLogEntryOverview = Load(() => import('../screen/AccessLogEntry/Overview'));
const EmailLogOverview = Load(() => import('../screen/User/EmailLog'));
const HomeDashboard = Load(() => import('../screen/HomeDashboard'));

const HardwareVersionOverview = Load(() => import('../screen/HardwareVersion/Overview'));
const FirmwareVersionOverview = Load(() => import('../screen/FirmwareVersion/Overview'));
const BatchOverview = Load(() => import('../screen/Batch/Overview'));
const RainBarrelOverview = Load(() => import('../screen/RainBarrel/RainBarrelOverview'));

const DeviceOverview = Load(() => import('../screen/Device/Overview'));
const DeviceEdit = Load(() => import('../screen/Device/Edit'));
const AddKpnDeviceEditModal = Load(() => import('../screen/Device/AddKpnDeviceModal'));
const DeviceOperatorView = Load(() => import('../screen/Device/OperatorView'));

const UplinkOverview = Load(() => import('../screen/Uplink/Overview'));
const DownlinkOverview = Load(() => import('../screen/Downlink/Overview'));

const LppRecordOverview = Load(() => import('../screen/LppRecord/LppRecordOverview'));


const LoginForgot = Load(() => import('spider/semantic-ui/LoginForgot'));
const ResetPassword = Load(() => import('spider/semantic-ui/ResetPassword'));
const AccountDetails = Load(() => import('../screen/AccountDetails'));
const Changelog = Load(() => import('../screen/Changelog'));


const NotFound = Load(() => import('../container/NotFound'));

export default class Router extends Component {
  static propTypes = {
    store: PropTypes.instanceOf(View).isRequired,
    moduleRepository: PropTypes.instanceOf(ModuleRepository).isRequired
  };

  // react-router is a bit too verbose so I made a shorthand
  route = Screen => {
    const { moduleRepository } = this.props;
    return rProps => <Screen {...rProps} viewStore={this.props.store} moduleRepository={moduleRepository} />;
  };

  redirectFromHome = () => {
    const { currentUser } = this.props.store;
    let uri = currentUser.isSuperuser ? (
      '/assets/user/overview'
    ) : (
      '/operations/dashboard'
    );

    return <Redirect to={uri} />;
  };

  render() {
    const { moduleRepository } = this.props;

    return (
      <Switch>
        <Route path="/" exact render={this.redirectFromHome} />

        <Route path="/operations/dashboard" render={this.route(HomeDashboard)} />

        <Route path="/assets/user/overview" render={this.route(UserOverview)} />
        <Route path="/assets/user/:id/edit" render={this.route(UserEdit)} />
        <Route path="/assets/user/add" render={this.route(UserEdit)} />
        <Route path="/assets/user/email-log/overview" render={this.route(EmailLogOverview)} />

        <Route path="/assets/global-value/overview" render={this.route(GlobalValueOverview)} />
        <Route path="/assets/global-value/:id/edit" render={this.route(GlobalValueEdit)} />

        <Route path="/operations/hardware/overview" render={this.route(HardwareVersionOverview)} />
        <Route path="/operations/firmware/overview" render={this.route(FirmwareVersionOverview)} />
        <Route path="/operations/batch/overview" render={this.route(BatchOverview)} />
        <Route path="/operations/rain-barrel/overview" render={this.route(RainBarrelOverview)} />

        <Route path="/operations/device/overview" render={this.route(DeviceOverview)} />
        <Route path="/operations/device/:id/edit" render={this.route(DeviceEdit)} />
        <Route path="/operations/device/add" render={this.route(AddKpnDeviceEditModal)} />
        <Route path="/operations/device/:id/operatorview" render={this.route(DeviceOperatorView)} />

        <Route path="/kpn/uplink/overview" render={this.route(UplinkOverview)} />
        <Route path="/kpn/downlink/overview" render={this.route(DownlinkOverview)} />

        <Route path="/telemetry/lpp-record/overview" render={this.route(LppRecordOverview)} />

        <Route path="/assets/global-file/overview" render={this.route(GlobalFileOverview)} />
        <Route path="/assets/global-file/:id/edit" render={this.route(GlobalFileEdit)} />

        <Route path="/audit/access-log/overview" render={this.route(AccessLogEntryOverview)} />

        <Route path="/account/details" render={this.route(AccountDetails)} />
        <Route path="/account/changelog" render={this.route(Changelog)} />
        <Route path="/login/forgot" render={this.route(LoginForgot)} />
        <Route path="/user/:id/reset-password/:token" render={this.route(ResetPassword)} />

        <Route render={this.route(NotFound)} />
        {moduleRepository.getRoutes().map(moduleRoute => {
          return <Route path={moduleRoute.path} render={this.route(moduleRoute.screen)} />
        })}

        <Route render={this.route(NotFound)} />
      </Switch>
    );
  }
}
