export default {
  nav: {
    main: {
      assets: 'Stamgegevens',
      operations: 'Operatie',
      kpn: 'KPN',
      telemetry: 'Telemetrie',
    },
    telemetry: {
      lppRecord: 'Lpp Records',
    },
    kpn: {
      kpnDevices: 'Apparaten',
      uplink: 'Uplinks',
      downlink: 'Downlinks',
    },
    operations: {
      devices: 'Apparaten',
      firmwareVersion: 'Software versie',
      hardwareVersion: 'Hardware versie',
      batch: 'RLC oplage',
      rainBarrel: 'Regenton',
    },
    assets: {
      users: 'Gebruikers',
      globalValues: 'Instellingen',
      globalFiles: 'Bijzondere bestanden',
    },
    audit: {
      accessLog: 'Toegangslog',
    },
    account: {
      account: 'Account',
      changelog: 'Changelog'
    }
  },
  user: {
    overview: {
      title: 'Gebruikers',
      addButton: 'Nieuwe Gebruiker',
    },
    create: {
      title: 'Gebruiker Aanmaken',
    },
    edit: {
      title: 'Gebruiker Aanpassen',
    },
    field: {
      fullName: { label: 'Naam' },
      firstName: { label: 'Voornaam' },
      lastName: { label: 'Achternaam' },
      email: { label: 'E-mail' },
      language: { label: 'Taal' },
      password: { label: 'Wachtwoord' },
      dateJoined: { label: 'Toegevoegd Op' },
      groups: {
        label: 'Groepen',
        value: {
          superuser: 'Supergebruiker',
          admin: 'Administrator',
          manager: 'Manager',
          operator: 'Operator',
          external_api: 'API',
          production: 'Productie',
        },
        empty: 'Geen groepen toegekend',
      },
      isActive: {
        label: 'Users',
        options: {
          all: 'All',
          yes: 'Active',
          no: 'Not Active',
        }
      },
    },
    login: {
      title: 'Inloggen',
      forgotPasswordLink: 'Wachtwoord vergeten?',
      errors: {
        invalidCredentials: 'E-mail of wachtwoord is niet correct',
        unknown: 'Onbekende fout, status code: {{status}}',
      },
      loginButton: 'Inloggen',
    },
    passwordForgot: {
      title: 'Wachtwoord Vergeten',
      requestButton: 'Verstuur Email',
      requestedEmailText:
        'Er is een email met een wachtwoord reset code verzonden naar uw email adres.',
      loggedInError: 'Je bent ingelogd. Je kunt alleen wachtwoord vergeten aanvragen als je uitgelogd bent.',
    },
    resetPassword: {
      title: 'Wachtwoord Reset',
      resetButton: 'Reset',
      saveSuccess: 'Wachtwoord is succesvol gereset',
      loggedInError: 'Je bent ingelogd. Je kunt alleen wachtwoord resetten als je uitgelogd bent.',
    },
    changePassword: {
      title: 'Wachtwoord Aanpassen',
      field: {
        passwordOld: {
          label: 'Huidig wachtwoord',
        },
        passwordNew: {
          label: 'Nieuw wachtwoord',
        },
      },
      saveButton: 'Opslaan',
    },
    account: {
      title: 'Profiel',
      logoutButton: 'Uitloggen',
      info: {
        title: 'Gegevens',
      },
      password: {
        title: 'Wachtwoord Veranderen',
      },
      logout: {
        title: 'Uitloggen',
        confirm: 'Weet u zeker dat u wilt uitloggen?'
      },
    },
  },
  globalValue: {
    edit: {
      title: 'Waarde wijzigen van algemene instelling',
    },
    overview: {
      title: 'Algemene instellingen',
    },
    field: {
      key: {
        label: 'Naam instelling',
      },
      value: {
        label: 'Waarde',
      },
      writable: {
        label: 'Schrijfbaar?',
      },
      readable: {
        label: 'Leesbaar?',
      },
    },
  },
  globalFile: {
    edit: {
      title: 'Bijzonder bestand bewerken',
    },
    overview: {
      title: 'Bijzondere bestanden',
    },
    field: {
      key: {
        label: 'Naam voor bestand',
      },
      value: {
        label: 'Bestand',
      },
      writable: {
        label: 'Schrijfbaar?',
      },
      readable: {
        label: 'Leesbaar?',
      },
    },
  },
  accessLogEntry: {
    overview: {
      title: 'Auditlog',
    },
    field: {
      originalUser: {
        label: 'Oorspronkelijke gebruiker',
      },
      masqueradingAsUser: {
        label: 'Gemaskeerd als gebruiker',
      },
      ipAddress: {
        label: 'IP-adres',
      },
      method: {
        label: 'HTTP-methode',
      },
      endpoint: {
        label: 'Endpoint',
      },
      statusCode: {
        label: 'Statuscode',
      },
      additionalInfo: {
        label: 'Extra informatie',
      },
      requestTime: {
        label: 'Tijdstip van toegang',
      },
    },
  },
  myFilter: {
    custom: 'Nieuw',
    action: {
      unsetDefault: 'Gebruik niet als standaard',
      setDefault: 'Gebruik als standaard',
      setCurrent: 'Sla huidige filters op',
      delete: 'Verwijderen',
    },
  },
  tooltips: {
    masquerade: 'Voordoen als',
    edit: 'Aanpassen',
    delete: 'Verwijderen',
    view: 'Bekijk',
    restore: 'Herstel',
  },
  form: {
    search: 'Zoeken',
    actions: 'Acties',
    repeat: 'Herhaal',
    fileType: {
      pdf: 'PDF',
      image: 'IMG',
      xls: 'XLS',
      any: 'Anders',
      none: 'Nog geen bestand geüpload',
    },
    saveButton: 'Opslaan',
    multiPick: {
      searchPlaceholder: 'Selecteer…',
      selectedText: '$1 uit $2 geselecteerd',
      noneSelectedText: 'Geen geselecteerd',
      selectAllButton: 'Alle',
      selectNoneButton: 'Geen',
    },
    notifications: {
      saveSuccess: 'Succesvol opgeslagen',
      deleteSuccess: 'Succesvol verwijderd',
      saveError: 'Fout met opslaan ({{status}})',
      saveValError: 'Niet alle informatie is correct ingevuld',
      saveAuthError: 'Niet geauthorizeerd',
      newAppVersion:
        'Nieuwe versie beschikbaar, klik om de pagina te verversen',
    },
    submitButton: 'Verstuur',
    cancelButton: 'Annuleren',
    addButton: 'Toevoegen',
    deleteConfirmation: 'Weet u zeker dat u dit wilt verwijderen?',
    restoreConfirmation: 'Weet u zeker dat u dit wilt herstellen?',
    startDate: 'Start Datum',
    endDate: 'Eind Datum',
  },
  crash: {
    heading: 'Applicatie gecrasht',
    subHeading1: 'Onze excuses, maar de applicatie is gecrasht.',
    subHeading2: 'Ons team is op de hoogte gesteld.',
    reload: 'Herlaad pagina'
  },
  daycy: {
    week: {
      label: 'Wk',
      number: '{{weekNumber}}',
    },
    weekDay: {
      monday: 'Ma',
      tuesday: 'Di',
      wednesday: 'Wo',
      thursday: 'Do',
      friday: 'Vr',
      saturday: 'Za',
      sunday: 'Zo',
    },
    month: {
      january: 'Januari',
      february: 'Februari',
      march: 'Maart',
      april: 'April',
      may: 'Mei',
      june: 'Juni',
      july: 'Juli',
      august: 'Augustus',
      september: 'September',
      october: 'Oktober',
      november: 'November',
      december: 'December',
    },
  },
  'rainBarrel': {
    'overview': {
      'title': 'Regentonnen',
      'resultsTitle': 'Regentonnen zoek resultaten',
    },
    'nav': {
      'overview': 'Regentonnen'
    },
    'field': {
      id: { label: 'ID' },
      name: { label: 'Naam' },
      width: { label: 'Breedte (mm)' },
      height: { label: 'Hoogte (mm)' },
      depth: { label: 'Diepte (mm)' },
      volume: { label: 'Volume (m³)' },
      createdAt: { label: 'Aangemaakt op' },
      notes: { label: 'Notities' },
    },
    'create': {
      'title': 'Regenton toevoegen'
    },
    'delete': {
      'error': 'Kon regenton niet verwijderen',
      'success': 'Regenton verwijderd'
    },
    'edit': {
      'pagesTitle': 'Paginas',
      'title': 'Regenton bewerken'
    },
    'createButton': 'Regenton toevoegen',
    'editTooltip': 'Regenton bewerken',
  },
  'batch': {
    'overview': {
      'title': 'RLC oplage',
      'resultsTitle': 'RLC oplage zoekresultaten',
    },
    'nav': {
      'overview': 'RLC oplage'
    },
    'field': {
      id: { label: 'ID' },
      label: { label: 'Naam' },
      createdAt: { label: 'Gemaakt op' },
      notes: { label: 'Notities' },
    },
  },
  'hardwareVersion': {
    'overview': {
      'title': 'Hardware Versies',
      'resultsTitle': 'Zoek resultaten',
    },
    'nav': {
      'overview': 'Hardware versies'
    },
    'field': {
      id: { label: 'ID' },
      version: { label: 'Versie' },
      createdAt: { label: 'Aangemaakt op' },
      notes: { label: 'Notities' },
    },
    'create': {
      'title': 'Voeg hardware versie toe'
    },
    'delete': {
      'error': 'Kon hardware versie niet weghalen',
      'success': 'Hardware versie verwijderd'
    },
    'edit': {
      'pagesTitle': 'Paginas',
      'title': 'Bewerk hardware versie'
    },
    'createButton': 'Voeg toe',
    'editTooltip': 'Bewerk',
  },
  'firmwareVersion': {
    'overview': {
      'title': 'Firmware versies',
      'resultsTitle': 'Zoek resultaten',
    },
    'nav': {
      'overview': 'Firmware versies'
    },
    'field': {
      id: { label: 'ID' },
      version: { label: 'Versie' },
      link: { label: 'Link' },
      createdAt: { label: 'Aangemaakt op' },
      notes: { label: 'Notities' },
    },
    'create': {
      'title': 'Voeg toe'
    },
    'delete': {
      'error': 'Kon firmware versie niet verwijderen',
      'success': 'Firmware versie verwijderd'
    },
    'edit': {
      'pagesTitle': 'Paginas',
      'title': 'Bewerk firmware versie'
    },
    'createButton': 'Voeg firmware versie toe',
    'editTooltip': 'Bewerk firmware versie',
  },
  'location': {
    'overview': {
      'title': 'Locaties',
      'resultsTitle': 'Zoek resultaten',
    },
    'nav': {
      'overview': 'Locaties'
    },
    'field': {
      id: { label: 'ID' },
      identifier: { label: 'Identifier' },
      createdAt: { label: 'Aangemaakt op' },
      notes: { label: 'Notities' },
    },
    'create': {
      'title': 'Voeg locatie toe'
    },
    'delete': {
      'error': 'Kon locatie niet verwijderen ',
      'success': 'Locatie verwijderd'
    },
    'edit': {
      'pagesTitle': 'Paginas',
      'title': 'Bewerk locatie'
    },
    'createButton': 'Voeg locatie toe',
    'editTooltip': 'Bewerk locatie',
  },
  'device': {
    'overview': {
      'title': 'Apparaten',
      'resultsTitle': 'Zoek resultaten',
    },
    'nav': {
      'overview': 'Apparaten'
    },
    'field': {
      id: { label: 'ID' },
      cohort: { label: 'Cohort' },
      devui: { label: 'Apparataat UI' },
      appkey: { label: 'Apparataat key' },
      firmwareVersion: { label: 'Firmware' },
      hardwareVersion: { label: 'Hardware' },
      batch: { label: 'Oplage' },
      rainBarrel: { label: 'Regenton' },
      operationalStatus: { label: 'Operationele status' },
      group: { label: 'Groep' },
      location: { label: 'Locatie' },
      identifier: { label: 'Identifier' },
      createdAt: { label: 'Aangemaakt op' },
      notes: { label: 'Notities' },
      shipped: { label: 'Geleverd' },
      lastMessageAt: { label: 'Bericht status' },
    },
    'create': {
      'title': 'Voeg Apparaat toe'
    },
    'delete': {
      'error': 'Kon apparaat niet verwijderen ',
      'success': 'Apparaat verwijderd'
    },
    'edit': {
      'pagesTitle': 'Paginas',
      'title': 'Bewerk Apparaat'
    },
    'createButton': 'Voeg apparaat toe',
    'editTooltip': 'Bewerk apparaat',
  },
  uplink: {
    overview: {
      title: 'Uplinks'
    },
    field: {
      id: { label: 'ID' },
      payload: { label: 'Payload' },
      receivedAt: { label: 'Ontvangen om' },
      processedAt: { label: 'Verwerkt om' },
    }
  },
  downlink: {
    overview: {
      title: 'Downlinks',
      resultsTitle: 'Zoek resultaten',
    },
    field: {
      id: { label: 'ID' },
      device: { label: 'Apparaat ID' },
      payload: { label: 'Payload' },
      status: { label: 'Status' },
      failureReason: { label: 'Reden falen' },
      sentAt: { label: 'Verzonden om' },
      createdAt: { label: 'Aangemaakt om' },

      pumpActivated: { label: 'Pomp activeren' },
      pumpDuration: { label: 'Duur in seconden (1 - 3600)' },
      pumpChoice: { label: 'Pomp keuze' },

      selectionValveActivated: { label: 'Beregeningsklep activeren' },
      selectionValveChoice: { label: 'Beregeningsklep keuze' },

      dumpValveDuration: { label: 'Open stort klep voor x seconden' },
      dumpValveChoice: { label: 'Stortklep keuze' },
      dumpValveActivated: { label: 'Stortklep activeren' },
    },
    control: {
      pump: 'Pomp aansturen',
      dump_valve: 'Stortklep aansturen'
    }
  },
  pageNotFoundMessage: 'Pagina niet gevonden',
  brandingMessage: 'Powered by Theta Vision',

  filters: {
    deletedFilter: {
      label: 'Verwijderd',
      withoutDeleted: 'Zonder verwijderd',
      onlyDeleted: 'Alleen verwijderd',
      bothDeletedAndNot: 'Beide'
    },
    notesFilter: {
      label: 'Notities',
      withoutNotes: 'Zonder notities',
      onlyNotes: 'Alleen notities',
      bothNotesAndNot: 'Beide'
    }

  },
  lppRecord: {
    overview: {
      title: 'LPP Records',
      resultsTitle: 'Zoekresultaten'
    },
    field: {
      id: { label: 'ID' },
      createdAt: { label: 'Aangemaakt op' },
      channel: { label: 'Kanaal' },
      value: { label: 'Waarde' },
      dataType: { label: 'Data Type' },
      device: { label: 'Apparaat id' },
      uplink: { label: 'Uplink id' },
    }
  },
};
